import { z } from 'zod'

// pre-load
const HartaEnvSchema = z.enum([
  'production',
  'staging',
  'preview',
  'development',
  'test',
] as const)
export type HartaEnv = z.infer<typeof HartaEnvSchema>

export function getInitialHartaEnv(): HartaEnv {
  if (process.env.NODE_ENV === 'production') {
    // If external connection string override is present, mark as preview build
    if (process.env.HUSTHERE_DATABASE_URL) {
      return 'preview'
    }

    // If git release tag is present, mark as production build, otherwise mark as staging build
    return process.env.GIT_RELEASE_TAG ? 'production' : 'staging'
  }

  return process.env.NODE_ENV === 'test' ? 'test' : 'development'
}

const CoreEnvSchema = z.object({
  NODE_ENV: z.enum(['production', 'development', 'test'] as const),
  HARTA_ENV: HartaEnvSchema,
})
const DatabaseEnvSchema = z.object({
  HUSTHERE_DATABASE_URL: z.string(),
  HUSTHERE_DATABASE_DIRECT_URL: z.string(),

  DOCUMENT_DATABASE_URL: z.string(),

  CACHE_URL: z.string(),
})
const MediaDeliveryEnvSchema = z.object({
  MEDIA_CDN_URL: z.string(),
  MEDIA_CDN_PUBLIC_KEY: z.string(),
})
const GeolocationEnvSchema = z.object({
  GOOGLE_MAPS_API_KEY: z.string(),
  MAPBOX_API_KEY: z.string(),
  FOURSQUARE_API_KEY: z.string(),
  IP2LOCATION_API_KEY: z.string(),
})
const OAuthEnvSchema = z.object({
  GOOGLE_CLIENT_ID: z.string(),
  GOOGLE_CLIENT_SECRET: z.string(),
  INSTAGRAM_CLIENT_ID: z.string(),
  INSTAGRAM_CLIENT_SECRET: z.string(),
  TIKTOK_CLIENT_ID: z.string(),
  TIKTOK_CLIENT_SECRET: z.string(),

  SESSION_SECRET: z.string(),
})
const InfrastructureEnvSchema = z.object({
  GOOGLE_CLOUD_PROJECT_ID: z.string(),
  GOOGLE_CLOUD_STORAGE_CLIENT_SECRET: z.string(),

  SENTRY_ORG: z.string().optional(),
  SENTRY_PROJECT: z.string().optional(),
  SENTRY_DSN: z.string().optional(),
  SENTRY_AUTH_TOKEN: z.string().optional(),
  BUILD_TAG: z.string().optional(),
})
const IntegrationsEnvSchema = z.object({
  AMADEUS_HOST: z.string(),
  AMADEUS_CLIENT_ID: z.string(),
  AMADEUS_CLIENT_SECRET: z.string(),

  VIATOR_HOST: z.string(),
  VIATOR_API_KEY: z.string(),

  STRIPE_ACCOUNT_ID: z.string(),
  STRIPE_PUBLIC_KEY: z.string(),
  STRIPE_SECRET_KEY: z.string(),

  RESEND_API_KEY: z.string(),
})

const schema = CoreEnvSchema.merge(DatabaseEnvSchema)
  .merge(MediaDeliveryEnvSchema)
  .merge(GeolocationEnvSchema)
  .merge(OAuthEnvSchema)
  .merge(InfrastructureEnvSchema)
  .merge(IntegrationsEnvSchema)

declare global {
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof schema> {}
  }
}

export function init() {
  const parsed = schema.safeParse(process.env)

  if (parsed.success === false) {
    console.error(
      '❌ Invalid environment variables:',
      parsed.error.flatten().fieldErrors,
    )

    throw new Error('Invalid environment variables')
  }
}

// post-load

export const MODE = process.env.HARTA_ENV

export function isDeployedEnv() {
  return MODE === 'production' || MODE === 'staging' || MODE === 'preview'
}

/**
 * This is used in both `entry.server.ts` and `root.tsx` to ensure that
 * the environment variables are set and globally available before the app is
 * started.
 *
 * NOTE: Do *not* add any environment variables in here that you do not wish to
 * be included in the client.
 * @returns all public ENV variables
 */
export function getEnv() {
  return {
    MODE,
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_RELEASE: process.env.BUILD_TAG,
    MEDIA_CDN_URL: process.env.MEDIA_CDN_URL,
    MEDIA_CDN_PUBLIC_KEY: process.env.MEDIA_CDN_PUBLIC_KEY,
    MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
  }
}

type ENV = ReturnType<typeof getEnv>

declare global {
  var ENV: ENV
  interface Window {
    ENV: ENV
  }
}
